import axios from 'axios'
import { downloadBlob } from '../apiTools'

const ROOT_URL = "/api/v2"

const terminalsAPI = {
    // read
    getData: () => axios.get(`${ROOT_URL}/terminals`).then(r => r.data),
    getDataBySerialNumber: (sn) => axios.get(`${ROOT_URL}/terminals?sn=${sn}`).then(r => r.data),
    getDataByPosId: (posId) => axios.get(`${ROOT_URL}/terminals?pos=${posId}`).then(r => r.data),
    getDataByAdvancedSearch: (data) => axios.post(`${ROOT_URL}/terminals/advanced-search`, data).then(r => r.data),
    getOneMain: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/main`).then(r => r.data),
    getOneData: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/data`).then(r => r.data),
    getOneParameters: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/parameters`).then(r => r.data),
    getOneCompany: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/company`).then(r => r.data),
    getOneProcessing: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/processing`).then(r => r.data),
    getOneTms: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/tms`).then(r => r.data),
    getOneTmsDict: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/tms/dict`).then(r => r.data),
    getOneSdm: (data) => axios.get(`${ROOT_URL}/terminals/${data.id}/sdm`).then(r => r.data),
    getOneReceiptData: (terminalId) => axios.get(`${ROOT_URL}/terminals/${terminalId}/receipt`).then(r => r.data),
    getTerminalsBank: (terminalId) => axios.get(`${ROOT_URL}/terminals/${terminalId}/banks`).then(r => r.data),
    getTerminalBanksHistory: (terminalId) => axios.get(`${ROOT_URL}/terminals/${terminalId}/banksHistory`).then(r => r.data),
    // update 
    updateOneDeactivate: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/deactivate`, payload)
        .then(r => r.data),
    updateOneBlock: (id) => axios.put(`${ROOT_URL}/terminals/${id}/block`, {})
        .then(r => r.data),
    updateOneUnblock: (id) => axios.put(`${ROOT_URL}/terminals/${id}/unblock`, {})
        .then(r => r.data),
    updateOneKcv: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/kcv`, payload)
        .then(r => r.data),
    updateOneKeys: (id) => axios.put(`${ROOT_URL}/terminals/${id}/keys`, {})
        .then(r => r.data),
    updateOneSn: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/serial-number`, payload)
        .then(r => r.data),
    updateOneReceipt: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/receipt`, payload)
        .then(r => r.data),
    updateScreenSavers: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/terminalImages`, payload)
        .then(r => r.data),
    updateOneConnectionType: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/connection-type`, payload)
        .then(r => r.data),
    updateOneTemplate: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/template`, payload)
        .then(r => r.data),
    updateOneKeyloader: (id) => axios.put(`${ROOT_URL}/terminals/${id}/keyloader`)
        .then(r => r.data),
    updateOneSoftware: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/software`, payload)
        .then(r => r.data),
    assingOneTemplate: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/template/setOnly`, payload)
        .then(r => r.data),
    bindTerminalToTst: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/pos`, payload)
        .then(r => r.data),
   switchTerminalBank: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/switchBank`, payload)
        .then(r => r.data),
	updateConfigSettings: ({ id, payload }) => axios.put(`${ROOT_URL}/terminals/${id}/tms/templateParams`, payload)
        .then(r => r.data),
    // create
    createOne: (payload) => axios.post(`${ROOT_URL}/terminals`, payload)
        .then(r => r.data),
    createOneServiceTicketsComment: ({ id, payload }) => axios.post(`${ROOT_URL}/terminals/${id}/service-ticket-comments`, payload)
        .then(r => r.data),
    createOneServiceTicket: ({ id, payload }) => axios.post(`${ROOT_URL}/terminals/${id}/service-tickets`, payload)
        .then(r => r.data),
    createOneServiceComments: ({ id, payload }) => axios.post(`${ROOT_URL}/terminals/${id}/service-comments`, payload)
        .then(r => r.data),
    // Download
    downloadNotActiveMSPOS: (fileName) => axios.get(`${ROOT_URL}/exportInactiveTermsXlsx`, {responseType: 'blob'})
        .then(r => downloadBlob(r, fileName)),
}

export default terminalsAPI