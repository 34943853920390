import history from '../../../history';

const initialState = {  
	 
}

export default (state=initialState, action) => {
	  
	switch (action.type) {  
 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			localStorage.removeItem('user'); 
			history.push('/login')
			return initialState  
 
		default: 
			return state
	}
}